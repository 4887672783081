import { useEffect, useRef, useState } from "react";

export const useDropdownPosition = () => {
  const dropdownRef = useRef(null);
  const [position, setPosition] = useState({ top: "100%", adjusted: false });

  useEffect(() => {
    const adjustDropdownPosition = () => {
      if (!dropdownRef.current) return;

      const dropdown = dropdownRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - dropdown.bottom;
      if (spaceBelow < 0) {
        setPosition({ top: spaceBelow, adjusted: true });
      } else {
        setPosition({ top: "0", adjusted: false });
      }
    };

    adjustDropdownPosition();
    window.addEventListener("resize", adjustDropdownPosition);
    return () => window.removeEventListener("resize", adjustDropdownPosition);
  }, []);

  return { dropdownRef, position };
};

