import { ClickAwayListener } from "@material-ui/core";
import style from "../../../../styles/Header.module.scss";
import NextNavLink from "../../NavLink";
import { useState } from "react";
import { useLinkPrefix, useUserType } from "../../CustomHooks/others";

const SubNav = ({ currentToolSubtitleSub, item, handleNavSwap }) => {
  const [open, setOpen] = useState(false);
  const prefix = useLinkPrefix();

  return (
    <div className="d-flex flex-column gap-15">
      <div
        className="d-flex align-items-end"
        onClick={(e) => {
          handleNavSwap(item);
        }}
        role="button"
      >
        <NextNavLink
          href={`${prefix}/${
            item?.list && item?.list?.length > 0
              ? item?.list[0]?.specifiedPath
                ? item?.list[0]?.specifiedPath
                : item?.list[0]?.path
              : item.specifiedPath
              ? item.specifiedPath
              : item.path
          }`}
          activeClassName={style.activeLink}
        >
          <a
            className={`
              ${style.navLink}
            ${currentToolSubtitleSub === item.title ? style.activeSubNav : ""}
            `}
          >
            {item.title}
          </a>
        </NextNavLink>
      </div>

      {/* {open &&
        item?.list.map((sub, i) => (
          <NextNavLink
            href={`${prefix}/${
              sub.specifiedPath ? sub.specifiedPath : sub.path
            }`}
            activeClassName={style.activeLink}
            key={i}
          >
            <a
              className={`ml-3
              ${style.navLink}
              ${currentToolSubtitleSub === sub.title ? style.activeSubNav : ""}
            `}
            >
              {sub.title}
            </a>
          </NextNavLink>
        ))} */}
    </div>
  );
};

export const NavItem = ({
  path,
  listItem,
  toolName,
  currentToolSubtitle,
  activeSubNav,
  displayAnbindungen,
  currentToolSubtitleSub,
  navRef,
  handleNavSwap,
}) => {
  const user = useUserType();
  const [open, setOpen] = useState(false);
  const prefix = useLinkPrefix();
  const isMoreActive =
    listItem?.title === "Mehr" &&
    listItem?.list?.some((obj) => obj?.title === currentToolSubtitle);

  return listItem.title == "Anbindungen" &&
    !displayAnbindungen ? null : !listItem?.list ? (
    listItem.external ? (
      <a
        href={listItem.path}
        target="_blank"
        rel="noopener noreferrer"
        ref={navRef || undefined}
      >
        {listItem.title}
      </a>
    ) : (
      <NextNavLink
        href={`${prefix}/${
          listItem.specifiedPath ? listItem.specifiedPath : listItem.path
        }`}
        className={style.navLink}
        activeClassName={style.activeLink}
      >
        <a
          className={
            currentToolSubtitle?.toLowerCase() === listItem.title.toLowerCase()
              ? style.active
              : ""
          }
          ref={navRef || undefined}
        >
          {listItem.title}
        </a>
      </NextNavLink>
    )
  ) : (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={style.dropdownNavItem} ref={navRef || undefined}>
        <a
          className={
            "d-flex align-items-center justify-content-between" +
            " " +
            (currentToolSubtitle === listItem.title || isMoreActive
              ? style.active
              : "") +
            " " +
            (open && style.openNavDropdown)
          }
          onClick={() => setOpen(!open)}
        >
          {listItem.title}
          <span className="material-symbols-outlined text-blue ml-2">
            keyboard_arrow_down
          </span>
        </a>
        {open && (
          <div
            className={style.navDropdown}
            style={{ left: 0, top: "55px", minWidth: "100%" }}
            onClick={() => setOpen(false)}
          >
            {listItem?.list.map((item, i) =>
              item?.list ? (
                <SubNav
                  currentToolSubtitleSub={currentToolSubtitleSub}
                  item={item}
                  handleNavSwap={handleNavSwap}
                  key={i}
                />
              ) : listItem.title === "Mehr" ? (
                <NextNavLink
                  href={`${prefix}/${
                    item?.specifiedPath ? item?.specifiedPath : item?.path
                  }`}
                  activeClassName={style.activeLink}
                  key={i}
                >
                  <a
                    className={`
                    ${style.navLink}
                   ${
                     currentToolSubtitleSub === item?.title
                       ? style.activeSubNav
                       : ""
                   }
                  `}
                    onClick={() => {
                      handleNavSwap(item);
                    }}
                    role="button"
                  >
                    {item?.title}
                  </a>
                </NextNavLink>
              ) : (
                <NextNavLink
                  href={`${prefix}/${
                    item?.specifiedPath ? item?.specifiedPath : item?.path
                  }`}
                  activeClassName={style.activeLink}
                  key={i}
                >
                  <a
                    className={`
                      ${style.navLink}
                    ${
                      currentToolSubtitleSub === item?.title
                        ? style.activeSubNav
                        : ""
                    }
                    `}
                  >
                    {item?.title}
                  </a>
                </NextNavLink>
              )
            )}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};
