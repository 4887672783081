import React from "react";
import "../public/bootstrap.css";
import "../styles/globals.css";
import "../public/index.scss";
import "../public/general.scss";
import "../public/new.scss";
import "../public/media-queries.scss";
import "../public/Slider.scss";
import "../public/hover.css";
import "../public/animations.css";
import "../public/global-icons.css";
import "react-phone-input-2/lib/bootstrap.css";
import Head from "next/head";
import "@fullcalendar/common/main.css";
import "@fullcalendar/timegrid/main.css";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import Script from "next/script";
import * as ga from "../analytics";
import { useRouter } from "next/router";
import { UserProvider } from "@auth0/nextjs-auth0";
import NavBar from "../src/reusable/NavBar";
import axios from "axios";
import { LocalUserProvider } from "../src/AuthContext/UserContext";
config.autoAddCss = false;

export default function MyApp({ Component, pageProps }) {
  const router = useRouter();

  const getLayout = Component.getLayout || ((page) => page);
  const showNav = Component.showNav !== false;

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  React.useEffect(() => {
    ga.initGA();
    ga.logPageView();
    const handleRouteChange = (url) => {
      ga.logPageView();
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    // Clean up the event listener
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  React.useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.defer = true;
    script.src = `//js.hs-scripts.com/${process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_CODE}.js`;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);


  React.useEffect(() => {
    const handleRouteChange = (url) => {
      const pageTitle = document.title || "Radiologientz";
      if (window._hsq) {
        window._hsq.push(["setPath", url]);
        window._hsq.push(["setTitle", pageTitle]);
        window._hsq.push(["trackPageView"]);

      }
    };
  
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <title>Radiologienetz</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
          rel="stylesheet"
        />
        <meta name="theme-color" content="#ffffff"></meta>
      </Head>
      <Script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></Script>
      <UserProvider>
        <LocalUserProvider>
          {showNav && <NavBar />}
          {getLayout(<Component {...pageProps} />)}
        </LocalUserProvider>
      </UserProvider>
    </>
  );
}
