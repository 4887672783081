import { useRef, useState, useEffect } from "react";
import style from "./style.module.scss";
import { ClickAwayListener } from "@mui/base";
import IconHelper from "../IconMaterial";
import { BeatLoader } from "react-spinners";
import { Button } from "./Buttons";

function SingleSelect({
  selected,
  setSelected,
  initialSelectState = null,
  label,
  name,
  options = [],
  dropdownStyles,
  error,
  size,
  formatter,
  loading,
  onChange,
  extraClassname = "",
  disabled,
  isRequired,
  openUp,
  selectKey,
  type = "normal-select",
  onInputChange,
  searchText,
  totalDataCount,
  isClearButtonSticky = true,
  resetApiQuery,
}) {
  const btnRef = useRef();
  const dropdownRef = useRef(null);
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [inputError, setError] = useState(false);
  const [listMinWidth, setListMinWidth] = useState(0);
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    if (btnRef.current) {
      setListMinWidth(btnRef.current.getBoundingClientRect().width);
    }
  }, [selected]);

  // useEffect(() => {
  //   setFilteredOptions(options);
  // }, [selected, options]);

  useEffect(() => {
    if (type === "paginated-select") {
      setFilteredOptions(options);
    }
  }, [options, type]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSelected && setSelected(initialSelectState);
    onChange && onChange(null, name);
    setQuery(value);
    onInputChange && onInputChange(value);
    setFilteredOptions(
      options?.filter((el) =>
        formatter
          ? formatter(el)?.toLowerCase()?.includes(value.toLowerCase())
          : el.toLowerCase()?.includes(value.toLowerCase())
      )
    );
  };

  const handleSelect = (el) => {
    const value = selectKey ? el[selectKey] : el;
    setSelected && setSelected(value);
    onChange && onChange(value, name);
    setOpen(false);
  };

  const getSelectBtnClasses = () => {
    const baseClasses = [style.selectBtn];
    if (open) baseClasses.push(style.active);
    if (error) baseClasses.push(style.error);
    if (inputError) baseClasses.push(style.error);

    const sizeClassMap = {
      sm: style.size_sm,
      md: style.size_md,
      lg: style.size_lg,
    };
    if (sizeClassMap[size]) baseClasses.push(sizeClassMap[size]);

    const isDisabled =
      disabled ||
      (!options && type === "normal-select") ||
      (options?.length === 0 && type === "normal-select") ||
      (totalDataCount === 0 && type === "paginated-select");

    if (isDisabled) baseClasses.push(style.selectBtnDisabled);

    return baseClasses.join(" ");
  };

  const getDisplayValue = () => {
    if (!selected) return query;

    const selectedOption = selectKey
      ? options?.find((x) => x[selectKey] === selected)
      : selected;

    return formatter ? formatter(selectedOption) : selectedOption;
  };

  // useEffect(() => {
  //   if (open && dropdownRef.current) {
  //     dropdownRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  //   }
  // }, [open]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpen(false);
        if (!selected) {
          setQuery("");
          resetApiQuery && resetApiQuery();
        }
      }}
    >
      <div className={`${style.singleSelect} ${extraClassname}`}>
        <div
          ref={btnRef}
          onClick={() => {
            setOpen(true);
            setFilteredOptions(options);
          }}
          className={getSelectBtnClasses()}
        >
          <input
            type="text"
            name={name}
            value={getDisplayValue()}
            onChange={handleInputChange}
            className={style.selectInput}
            disabled={
              disabled ||
              (!options && type === "normal-select") ||
              (options?.length === 0 && type === "normal-select") ||
              (totalDataCount === 0 && type === "paginated-select")
            }
          />

          {!query && !selected && (
            <label className={style.selectInputLabel}>
              {label || "Select"}
              {isRequired && label && <span className="text-error">&nbsp;*</span>}
            </label>
          )}

          <IconHelper
            iconName={open ? "search" : "keyboard_arrow_down"}
            className={style.chevron}
          />
        </div>

        {open && (
          <div
            ref={dropdownRef}
            className={`${style.dropdownContentContainer} ${
              size === "sm"
                ? style.size_sm
                : size === "md"
                ? style.size_md
                : size === "lg"
                ? style.size_lg
                : ""
            }`}
            style={{
              minWidth: size === "sm" ? "140px" : listMinWidth,
              bottom: openUp ? "50px" : "auto",
              ...dropdownStyles,
            }}
          >
            {loading ? (
              <BeatLoader color="#009fe3" size={10} />
            ) : (
              <div className={style.dropdownContent}>
                {/* Message for empty options for paginated select */}
                {type === "paginated-select" &&
                  options?.length === 0 &&
                  searchText && (
                    <p className={style.emptyOptionText}>
                      {searchText || "Bitte geben Sie einen Wert ein"}
                    </p>
                  )}

                {/* Message for empty options for normal select */}
                {type === "normal-select" &&
                  filteredOptions?.length === 0 &&
                  searchText && (
                    <p className={style.emptyOptionText}>
                      {searchText || "Bitte geben Sie einen Wert ein"}
                    </p>
                  )}
                {/* Render filtered options */}
                {filteredOptions?.map((el, index) => (
                  <button
                    key={index}
                    onClick={() => handleSelect(el)}
                    className={
                      ((selectKey && el[selectKey] === selected) ||
                        (!selectKey && selected === el)) &&
                      style.selectedItem
                    }
                    type="button"
                  >
                    {formatter ? formatter(el) : el}
                  </button>
                ))}
              </div>
            )}

            <div className={isClearButtonSticky && style.bottomBtn}>
              <Button
                btnText={"zurücksetzen"}
                onClick={() => {
                  setSelected && setSelected(initialSelectState);
                  setQuery("");
                  onChange && onChange(null, name);
                  onInputChange && onInputChange(initialSelectState);
                }}
                variant="bg-none"
              />
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}

export default SingleSelect;
