import { useEffect, useState, useRef } from "react";
import style from "./style.module.scss";
import axios from "axios";
import useApiLink from "../ApiLink";
import { BeatLoader } from "react-spinners";
import { useActiveUser, useDetails, useToken } from "../CustomHooks";
import Dropdown from "../Dropdown";
import { Button } from "./Buttons";
import { ClickAwayListener } from "@mui/base";
import { Tooltip } from "@mui/material";

export function FileInput({
  id,
  value,
  defaultIcon,
  uploadedIcon,
  buttonText,
  handleChange,
  type = "bild",
  btnStyle,
  round,
}) {
  const btnRef = useRef();
  const token = useToken();
  const fileInputRef = useRef(null);
  const [userDetails] = useActiveUser();
  const [show, setShow] = useState(false);
  const [uploading, setUploading] = useState(false);

  function triggerFileInput(id) {
    document.getElementById(id).click();
  }

  function handleFileUpload(files) {
    setUploading(true);
    const data = new FormData();
    data.append("file", files[0]);
    type == "bild" && handleChange(URL.createObjectURL(files[0]));

    axios
      .post(`${ApiLink}/media/upload-file`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUploading(false);
        handleChange(res.data);
      })
      .catch((err) => {
        setUploading(false);
        console.error(err.response);
      });
  }

  const IconName = () => {
    if (uploading) return "sync";
    if (value) return uploadedIcon ?? "description";
    return defaultIcon ?? "upload";
  };

  const dropdownContent = [
    type === "doc"
      ? {
          name: "anzeigen",
          action: () => window.open(value, "_blank"),
        }
      : null,
    {
      name: "Ersetzen",
      action: () => {
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
      },
    },
    {
      name: "Löschen",
      action: () => {
        handleChange("");
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      },
    },
  ].filter(Boolean);

  return (
    <div className={"position-relative"}>
      <input
        type="file"
        hidden
        id={id}
        onChange={(e) => handleFileUpload(e.target.files)}
        ref={fileInputRef}
        accept={type == "bild" ? ".jpg,.jpeg,.png,.svg, .webp" : "/*"}
      />
      {!value ? (
        <Button
          type="button"
          iconClass={uploading ? "animate-upload" : ""}
          iconName={IconName()}
          onClick={() => triggerFileInput(id)}
          ref={btnRef}
          styles={btnStyle}
          btnText={buttonText ? buttonText : "Bild"}
        />
      ) : (
        <ClickAwayListener onClickAway={() => setShow(false)}>
          <>
            <Dropdown
              type="button"
              iconClass={uploading ? "animate-upload" : ""}
              iconName={IconName()}
              btnText={buttonText ? buttonText : "Bild"}
              dropdownContent={dropdownContent}
              variant={value ? "activeFile" : "file"}
            />
          </>
        </ClickAwayListener>
      )}
    </div>
  );
}

export default function UploadImage({
  value,
  id,
  handleChange,
  styles,
  btnOut,
  round,
  containerWidth,
  imgHeight,
  noBorderBottom,
  ...props
}) {
  return (
    <Tooltip
      title="Diese Formate werden unterstützt: .jpg, .jpeg, .png, .webp"
      placement="top"
    >
      <div
        className={style.imgUploadWrapper}
        style={{ width: containerWidth ?? "100%" }}
      >
        <div
          className={`${style.uploadImg} `}
          style={{
            height: imgHeight ?? "197px",
            borderRadius: noBorderBottom
              ? "20px 20px 0px 0px"
              : round
              ? "50%"
              : "20px",
          }}
        >
          {value && (
            <img
              src={value}
              alt="..."
              width="100%"
              height="100%"
              style={{
                borderRadius: noBorderBottom
                  ? "20px 20px 0px 0px"
                  : round
                  ? "50%"
                  : "20px",
              }}
            />
          )}
        </div>
        <div className={btnOut ? style.btnOut : style.drop}>
          <FileInput
            value={value}
            id={id}
            handleChange={handleChange}
            uploadedIcon={"image"}
            round={round}
            btnStyle={{ width: round ? "117.9px" : null }}
            {...props}
          />
        </div>
      </div>
    </Tooltip>
  );
}
