import React, { useEffect, useState } from "react";

import style from "../../../styles/Header.module.scss";
import { ClickAwayListener } from "@mui/base";
import {
  useDetails,
  useAllUsers,
  useActiveUser,
} from "../../reusable/CustomHooks";
import { useRouter } from "next/router";
import useApiLink from "../../reusable/ApiLink";
import axios from "axios";
import Loader from "../../reusable/Loader";
import useDataRequest from "../../../utils/AxiosInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, SearchBar } from "../FormComponent";

export default function SearchUser({ allowOnly = "all", activeUser }) {
  const router = useRouter();
  const ApiLink = useApiLink();
  const [userDetails] = useDetails();
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [metaData, setMetaData] = useState({});
  const [options, setOptions] = useState([
    {
      name: "Customer",
      accountType: "customer",
    },
    {
      name: "Partner",
      accountType: "partner",
    },
    {
      name: "Candidate",
      accountType: "candidate",
    },
  ]);
  const [selectedUser, setSelectedUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);

  const Clear = () => {
    sessionStorage.removeItem("selectedUser");
    sessionStorage.removeItem("token");
  };

  useEffect(() => {
    const storedUser = sessionStorage.getItem("selectedUser");
    if (storedUser) {
      setSelectedUser(JSON.parse(storedUser));
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${ApiLink}/admin/search-users?perPage=10&page=${page}&searchTerm=${searchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${userDetails.token}`,
          },
        }
      )
      .then((res) => {
        setUsers(res.data.data);
        setMetaData(res.data.metadata);
        setOptions(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [ApiLink, userDetails, searchQuery, page]);

  useEffect(() => {
    allowOnly == "all"
      ? setUsers(options)
      : setUsers(
          options.filter(
            (obj) => obj.user?.accountType === allowOnly.toUpperCase()
          )
        );
  }, [allowOnly, options]);

  useEffect(() => {
    if (Object.keys(selectedUser).length !== 0) {
      sessionStorage.setItem("selectedUser", JSON.stringify(selectedUser));
      sessionStorage.setItem("token", selectedUser.token);
    }

    setSelected(
      selectedUser?._id
        ? `${selectedUser.title !== "Keine" ? selectedUser.title : ""} ${
            selectedUser?.vorname ?? ""
          } ${selectedUser?.nachname ?? ""}`
        : null
    );
  }, [selectedUser]);

  const filterUsers = (value) => {
    let filteredResults;
    if (value !== "") {
      users.some((obj) => obj.user?.type == "subuser")
        ? (filteredResults = options.filter((item) => {
            return (
              item.user?.vorname.toLowerCase().includes(value) ||
              item.user?.nachname.toLowerCase().includes(value)
            );
          }))
        : (filteredResults = options.filter((item) => {
            return item.user?.accountName?.toLowerCase().includes(value);
          }));

      setUsers(filteredResults);
    } else setUsers(options);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={style.navBtnWrapper}>
        <button className={style.userDetails} onClick={() => setOpen(!isOpen)}>
          {selected
            ? selected
            : `${activeUser.title !== "Keine" ? activeUser.title : ""} ${
                activeUser.vorname ?? ""
              }  ${activeUser.nachname ?? ""}`}
          <FontAwesomeIcon icon={["fas", "chevron-down"]} />
        </button>
        {isOpen && (
          <div
            className={style.navDropdown}
            style={{ right: 24, left: "auto", top: "40px", width: "300px" }}
          >
            <>
              <SearchBar
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value?.toLowerCase())}
              />
              {!searchQuery && <p>Bitte Accountnamen eingeben</p>}

              {searchQuery &&
                users.map(
                  (el, index) =>
                    activeUser._id !== el._id && (
                      <>
                        {/*   <p
                          key={index}
                          onClick={() => {
                            !el.user?.accountName
                              ? setSelected(el.user.vorname)
                              : setSelected(el.user.accountName);
                            setSelectedUser(el);
                            router.reload();
                          }}
                        >
                          {el.accountName}
                        </p>*/}
                        <Account
                          el={el}
                          key={index}
                          setSelected={setSelected}
                          setSelectedUser={setSelectedUser}
                        />
                      </>
                    )
                )}
              {searchQuery && metaData.hasNextPage && (
                <Button
                  variant="bg-none"
                  btnText="10 weitere anzeigen"
                  onClick={() => setPage(page + 1)}
                />
              )}
              {selected && (
                <Button
                  onClick={() => {
                    Clear();
                    router.reload();
                  }}
                  variant="bg-none"
                  btnText="zurücksetzen"
                  className={style.bottomBtn}
                />
              )}
            </>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}

function Account({ setSelected, setSelectedUser, el }) {
  const router = useRouter();
  const [show, setShow] = useState(false);
  const [filterValue, setFilterValue] = useState("");

  const filteredUsers = el.users?.filter(
    (user) =>
      user.vorname?.toLowerCase().includes(filterValue.toLowerCase()) ||
      user.nachname?.toLowerCase().includes(filterValue.toLowerCase())
  );

  return (
    <ClickAwayListener onClickAway={() => setShow(false)}>
      <div className={style.account}>
        <button
          onClick={() => setShow(!show)}
          style={{ whiteSpace: "break-spaces" }}
          className={style.navLink}
        >
          {el.accountName}
        </button>
        {show && (
          <div className={style.usersPop}>
            {
              <SearchBar
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
              />
            }
            {filteredUsers.map((user, index) => (
              <button
                key={index}
                onClick={() => {
                  setSelected(`${user.vorname} ${user.nachname}`);
                  setSelectedUser(user);
                  router.reload();
                }}
                className={style.navLink}
              >
                {user.title !== "Keine" ? user.title : ""} {user.vorname}{" "}
                {user.nachname}
              </button>
            ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}
