import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import InfoModal from "../InfoModal";

export const FormAlert = ({ isDirty, isSubmitting, submitted }) => {
  // isDirty is a boolean value to check if the form has been touched or not. If you have no way to keep track, you can set it to true as default
  // isSubmitting is a boolean value to check if the form is submitting

  const router = useRouter();
  const [show, setShow] = useState(false);
  const [pendingRoute, setPendingRoute] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false); // Flag to track user confirmation

  const handleRouteChange = (url) => {
    if (isDirty && !isSubmitting && !isConfirmed && !submitted) {
      setShow(true);
      setPendingRoute(url);
      router.events.emit("routeChangeError");
      throw "Route change aborted";
    }
  };

  useEffect(() => {
    // Add event listener for route changes
    router.events.on("routeChangeStart", handleRouteChange);

    // Add event listener for page reload or tab close
    const handleBeforeUnload = (e) => {
      if (isDirty && !isSubmitting) {
        const confirmationMessage =
          "Sie haben nicht gespeicherte Änderungen. Sind Sie sicher, dass Sie gehen möchten?";
        e.returnValue = confirmationMessage; // Standard for most browsers
        return confirmationMessage;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);


    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty, isSubmitting, isConfirmed, router.events]);

  return (
    show && (
      <InfoModal
        closeModal={() => setShow(false)}
        info="Ohne Speichern schließen"
        extra="Sie haben nicht gespeicherte Änderungen. Sind Sie sicher, dass Sie gehen möchten?"
        onNoClick={() => {
          setShow(false);
          setPendingRoute(null);
        }}
        action={() => {
          setShow(false);
          setIsConfirmed(true);
          setTimeout(() => {
            router.push(pendingRoute);
          }, 500);
        }}
      />
    )
  );
};
