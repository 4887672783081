import { useRef, useState, useEffect, act } from "react";
import { ClickAwayListener } from "@mui/base";
import IconWrapper from "./Icons";
import styles from "../../styles/reusable.module.scss";
import { Button } from "./FormComponent";

export default function Dropdown({
  type = "button" || "icon",
  iconPrefix,
  children,
  dropdownContent = [
    {
      name: "",
      action: () => {},
    },
  ],
  style = {},
  containerClassName = "",
  iconClass,
  btnText,
  iconName,
  variant,
  iconStyle,
  containerStyle,
  disabled,
  containerRef
}) {
  const [show, setShow] = useState(false);
  const btnRef = useRef();
  const divRef = useRef();
  const [minWidth, setMinWidth] = useState(0);

  useEffect(() => {
    if (btnRef.current) {
      const width = btnRef.current.getBoundingClientRect().width;
      setMinWidth(width);
    }

    // causes the page to scroll by an offset if the dropdown is at the bottom of the page
    if (show && divRef?.current && containerRef?.current) {
      // Measure the dropdown and container
      const dropdownRect = divRef.current.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();
      
      // Calculate space between dropdown and bottom of container
      const spaceBelow = containerRect.bottom - dropdownRect.top;
      
      // If the dropdown will overflow, scroll
      if (spaceBelow < dropdownRect.height) {
          containerRef.current.scrollBy({ top: dropdownRect.height, behavior: "smooth" });
      }
    }
  }, [show]);

  return (
    <ClickAwayListener onClickAway={() => setShow(false)}>
      <div
        className={`my-dropdown ${containerClassName}`}
        style={containerStyle}
      >
        {type == "button" ? (
          <Button
            ref={btnRef}
            btnText={btnText}
            iconName={iconName}
            iconClass={iconClass}
            variant={variant}
            styles={{ fontWeight: 300 }}
            onClick={() => setShow(!show)}
          />
        ) : (
          <IconWrapper
            iconName={iconName}
            className={iconClass}
            styling={iconStyle}
            onClick={() => setShow(!show)}
            disabled={disabled}
          />
        )}

        {show && !disabled && (
          <div
            className={styles.dropdownContent}
            style={{ minWidth: minWidth, ...style }}
            //onMouseLeave={() => setShow(!show)}
            ref={divRef}
          >
            {dropdownContent.map(({ name, action, invisible }, index) => (
              <button
                onClick={() => {
                  setShow(false);
                  action();
                }}
                key={index}
                className={invisible ? "d-none" : "d-block"}
                type="button"
              >
                {name}
              </button>
            ))}
            {children}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}
