import style from "../../styles/reusable.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BeatLoader } from "react-spinners";
import IconHelper from "./IconMaterial";

export default function IconWrapper({
  iconPrefix,
  iconName,
  styling,
  onClick,
  loading,
  className,
  color,
  old,
  iconClass,
  size,
  variant,
  disabled,
  ...props
}) {
  return (
    <button
      className={`${style.iconWrapper} ${className}`}
      style={styling}
      onClick={onClick}
      type="button"
      disabled={disabled}
      {...props}
    >
      {!old ? (
        <IconHelper
          size={size}
          iconName={iconName}
          variant={variant}
          className={iconClass ? iconClass : null}
        />
      ) : (
        <FontAwesomeIcon
          icon={[iconPrefix || "fas", iconName]}
          color={color ? color : "var(--color-blue)"}
        />
      )}
    </button>
  );
}
