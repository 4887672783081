export const checkFormErrors = (
  validations,
  values,
  setFormErrors,
  setInfoModal
) => {
  const errors = {};
  const errorFields = [];

  const isEmptyValue = (value) => {
    if (value === null || value === undefined) return true;
    if (typeof value === "string") return !value.trim();
    if (typeof value === "object") {
      if (Array.isArray(value)) return value.length === 0;
      if (value instanceof Date) return false;
      return Object.keys(value).length === 0;
    }
    return false;
  };

  const validateField = (rules, value, path) => {
    if (
      typeof rules === "object" &&
      !("required" in rules) &&
      !("wordLimit" in rules)
    ) {
      Object.entries(rules).forEach(([key, nestedRules]) => {
        const nestedValue = value?.[key];
        const nestedPath = path ? `${path}.${key}` : key;
        validateField(nestedRules, nestedValue, nestedPath);
      });
      return;
    }

    const fieldValue = value ?? "";

    if (rules.required && isEmptyValue(fieldValue)) {
      errors[path] = `${rules.title || path}`;
      const element = document.querySelector(`[name="${path}"]`);
      if (element) {
        errorFields.push({ element, path });
      }
      return;
    }

    if (
      rules.wordLimit &&
      typeof fieldValue === "string" &&
      fieldValue.trim().split(/\s+/).length > rules.wordLimit
    ) {
      errors[path] = ` ${rules.title || path}  ${rules.wordLimit}`;
      const element = document.querySelector(`[name="${path}"]`);
      if (element) {
        errorFields.push({ element, path });
      }
    }
  };

  Object.entries(validations).forEach(([field, rules]) => {
    validateField(rules, values[field], field);
  });

  if (Object.keys(errors).length > 0) {
    setFormErrors(errors);
    setInfoModal(true);

    if (errorFields.length > 0) {
      const firstError = errorFields[0].element;
      console.log(firstError);
      firstError.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });

      setTimeout(() => {
        firstError.focus();
      }, 500);
    }

    return true;
  }

  return false;
};
export const clearFormErrors = (
  validations = {},
  setFormErrors,
  formErrors
) => {
  console.log(validations);
  console.log(formErrors);
  if (validations[name]) {
    setFormErrors((prevErrors) => {
      const newErrors = { ...prevErrors };

      if (validations[name].required && value) {
        delete newErrors[name];
      }

      if (
        validations[name].wordLimit &&
        value.trim().split(/\s+/).length <= validations[name].wordLimit
      ) {
        delete newErrors[name];
      }

      return newErrors;
    });
  }
};
