import React, { useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import IconHelper from "../IconMaterial";
import IconWrapper from "../Icons";
import style from "./style.module.scss";
import { Button } from "./Buttons";
import Chip, { Tag } from "../chip";
import InfoModal from "../InfoModal";
import { ClickAwayListener } from "@mui/base";
import Loader from "../Loader";
import { FormAlert } from "./FormAlert";
import ErrorModal from "../ErrorModal";
import { checkFormErrors } from "./utils";
import { Diversity1Rounded } from "@mui/icons-material";

export default function FormWrapper({
  title,
  children,
  onSubmit,
  label,
  multi,
  submitText,
  disabled,
  iconBack = true,
  mdTitle,
  mdText,
  noAccess,
  formName,
  titles = [],
  values = {},
  onChange,
  validations,
  formErrors,
  setFormErrors,
  components = [],
}) {
  const router = useRouter();
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [closeForm, setCloseForm] = useState(false);
  const [prevValues, setPrevValues] = useState(values);
  const [isDirty, setIsDirty] = useState(true);
  const [errorModal, setErrorModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const headerRef = useRef(null);
  const [height, setHeight] = useState();
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (headerRef.current) {
      setHeight(headerRef.current.offsetHeight);
    }

    const handleResize = () => {
      if (headerRef.current) {
        setHeight(headerRef.current.offsetHeight);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (values) setLoading(false);
  }, [values]);

  useEffect(() => {
    const hasChanged = JSON.stringify(prevValues) !== JSON.stringify(values);

    if (hasChanged) {
      setIsDirty(true);
      setPrevValues(values);
    }
  }, [values, prevValues]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsDirty(false);
    setSubmitted(true);

    if (validations) {
      const hasErrors = checkFormErrors(
        validations,
        values,
        setFormErrors,
        setErrorModal
      );
      if (hasErrors) {
        setIsDirty(true);
        return;
      }
    }

    if (multi && count < components.length - 1) {
      setCount(count + 1);
    } else {
      setSubmitting(true);
      setLoading(true);

      try {
        await onSubmit(e);
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [count]);

  const handleCloseModal = () => setCloseForm(!closeForm);
  const handleBack = () => router.back();

  return (
    <>
      {!submitted && isDirty && (
        <FormAlert
          isDirty={isDirty}
          isSubmitting={loading || submitting}
          setIsDirty={setIsDirty}
          submitted={submitted}
        />
      )}
      {errorModal && (
        <InfoModal
          type="warning"
          info={"Hinweis"}
          closeModal={() => setErrorModal(false)}
        >
          <>
            <p>Bitte überprüfen Sie Ihre Eingabe</p>
            <div className="mt-1">
              {Object.entries(formErrors).map(([field, error], index) => (
                <p key={index}>{error}</p>
              ))}
            </div>
          </>
        </InfoModal>
      )}
      <div className={style.formContainer}>
        {closeForm && (
          <InfoModal
            action={handleBack}
            closeModal={handleCloseModal}
            info={mdTitle ?? "Ohne Speichern schließen"}
            extra={
              mdText ??
              "Wollen Sie die Änderungen verwerfen und die Seite schließen?"
            }
          />
        )}
        <div className={style.pageHeader} ref={headerRef}>
          <div className={style.header}>
            <button className={style.title} onClick={handleBack} type="button">
              {iconBack && <IconHelper iconName="chevron_left" />}
              {title}
              {label && (
                <div style={{ marginLeft: "24px" }}>
                  <Chip
                    label={label}
                    styles={{ textTransform: "capitalize" }}
                  />
                </div>
              )}
            </button>
            <div className={style.actions}>
              <IconWrapper iconName="close" onClick={handleBack} />
            </div>
          </div>
          {titles.length > 0 && (
            <div className="d-flex gap-16 mt-32">
              {titles.map((t, index) => (
                <Tag
                  key={index}
                  label={t}
                  active={index === count}
                  onClick={() => setCount(index)}
                />
              ))}
            </div>
          )}
        </div>
        <div
          style={{ marginTop: `calc(${height}px + 64px)`, paddingTop: "32px" }}
        >
          {loading ? (
            <Loader />
          ) : noAccess ? (
            <p className="mt-4">
              Dieser Benutzer ist nicht berechtigt, diese Seite anzuzeigen.
            </p>
          ) : (
            <form
              onSubmit={handleSubmit}
              data-hs-name={formName}
              data-hs-ignore="true"
            >
              <label for="cc-num" class="hidden">
                credit card HubspotCollectedFormsWorkaround
                https://community.hubspot.com/t5/APIs-Integrations/How-to-stop-collected-forms-from-collecting-a-form/m-p/299172#M28102
              </label>
              <input
                name="cc-num"
                class="hidden"
                required=""
                value="HubspotCollectedFormsWorkaround"
                id="cc-num"
              />
              <div className={style.content}>
                {multi ? components[count] : children}
              </div>
              <div className={multi ? style.navButtons : style.saveBtn}>
                {multi && count > 0 && (
                  <Button
                    variant="bg-white"
                    btnText="Zurück"
                    onClick={() => setCount(count - 1)}
                  />
                )}
                <Button
                  btnText={
                    multi && count < components.length - 1
                      ? "Weiter"
                      : submitText ?? "Speichern"
                  }
                  variant="bg-blue"
                  size="large"
                  type="submit"
                  disabled={disabled}
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
}
